import { FC } from 'react';
import { PageFormContainer, SectionTitle } from 'components';
import { GTSAdminPanel } from 'features/GTSAdminPanel/GTSAdminPanel';

export const GTSAdminPage: FC = () => {
  return (
    <PageFormContainer>
      {/* <SectionTitle section="gtsAdminPanel" /> */}
      <GTSAdminPanel />
    </PageFormContainer>
  );
};
