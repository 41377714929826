import { ContentContainer, MainContainer } from 'components';
import { FC } from 'react';

import styled from 'styled-components';

import { AdminErrorModal } from 'features/AdminPanel/AdminErrorModal';

import ConfigurationGrid from './components/ConfigurationGrid';
import Certificates from './components/Certificates';

export const FlexContainerWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`; //TODO REFACTOR THIS LAYOUT TO SOMETHING MORE TOUGHTFULL, MAYBE GRID

export const StyledFileLabel = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  margin-right: 1.25rem;
  flex: 1;
  margin-bottom: 2rem;
`;

export const StyledFileInput = styled.input`
  min-width: 35rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
`;

export const InputCover = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgb(0 0 0 / 5%);
  user-select: none;

  ::before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: block;
    content: 'Browse';
    padding: 0.4375rem 1.5625rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0;
  }
`;

export const AgGridContainer = styled.div`
  padding: 1.5625rem 5rem;
  width: 100%;
  margin-bottom: 2rem;
  height: 70vh;
`;

export const GTSAdminPanel: FC = () => {
  return (
    <MainContainer>
      <ContentContainer width="80%">
        <Certificates />
        <ConfigurationGrid />
      </ContentContainer>

      <AdminErrorModal />
    </MainContainer>
  );
};
