import { createContext, ReactElement, useMemo, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { lightTheme, darkTheme } from 'styles/themes';
import { GlobalStyle } from 'styles/globalStyles';
import { Router } from 'features/Router';
import ErrorBoundary from 'features/ErrorBoundary';
import { NotificationPopup } from 'features/NotificationPopup';
import { store } from 'store';
import { TENANT_NAME } from 'utils/constants';
import { useTenantInfo } from 'utils/hooks/useTenantInfo';
import { useLoginInfo } from 'utils/hooks/useLoginInfo';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { LoadingScreen } from 'components/LoadingScreen';
import { ITenant, TenantInfo } from 'utils/types/tenantType';
import { useGetConfigProperty } from 'features/GTSAdminPanel/GTSAdminPanelApi';

const muiThemeWrapper = createTheme({
  shape: {
    borderRadius: 4,
  },
});

const ApplicationContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

type ThemeName = 'lightTheme' | 'darkTheme';
export const ThemeFunctionsContext = createContext<{
  themeSwitch: () => void;
  themeName: ThemeName;
}>({
  themeSwitch: () => {
    console.error('Theme switch function not available');
  },
  themeName: 'lightTheme',
});

export const TenantInfoContext = createContext<ITenant>({} as ITenant);

const checkIfSysOverride = (): boolean => {
  const params = new URLSearchParams(window.location.search);
  if (params.has('sysOverride') && params.get('sysOverride') === 'false') {
    try {
      localStorage.removeItem('sysOverride');
      return false;
    } catch {
      return false;
    }
  }
  try {
    const isSysOverride = localStorage.getItem('sysOverride');
    if (isSysOverride === 'true') {
      return true;
    } else if (params.has('sysOverride') && params.get('sysOverride') === 'true') {
      localStorage.setItem('sysOverride', 'true');
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

const App = (): ReactElement => {
  const tenantName = useMemo(() => {
    if (checkIfSysOverride()) {
      return 'sysadmin';
    } else {
      return TENANT_NAME();
    }
  }, []);

  const { data: tenantInfo } = useTenantInfo(TENANT_NAME());
  const { data: tenantLogin } = useLoginInfo(tenantName);
  const { data: gtsLicense } = useGetConfigProperty('gts.service.licensed');
  const config = {
    auth: {
      clientId: tenantLogin?.clientIdentifier || '',
      authority: tenantLogin?.authority || '',
      redirectUri: `${window.location.origin}/app/`,
      postLogoutRedirectUri: `${window.location.origin}/logout/`,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  };

  const publicClientApplication = useMemo(() => new PublicClientApplication(config), [config]);

  const [currentTheme, setCurrentTheme] = useState<ThemeName>(
    (localStorage.getItem('theme') as ThemeName) || 'lightTheme',
  );

  const themeSwitch = () => {
    if (currentTheme === 'lightTheme') {
      setCurrentTheme('darkTheme');
      localStorage.setItem('theme', 'darkTheme');
    } else {
      setCurrentTheme('lightTheme');
      localStorage.setItem('theme', 'lightTheme');
    }
  };

  return (
    <MUIThemeProvider theme={muiThemeWrapper}>
      <ThemeProvider theme={currentTheme === 'lightTheme' ? lightTheme : darkTheme}>
        {tenantInfo && tenantLogin ? (
          <Provider store={store}>
            <MsalProvider instance={publicClientApplication}>
              <ErrorBoundary>
                <>
                  <GlobalStyle />
                  <BrowserRouter>
                    <ApplicationContainer>
                      <ThemeFunctionsContext.Provider
                        value={{ themeSwitch, themeName: currentTheme }}
                      >
                        <TenantInfoContext.Provider
                          value={{
                            info: tenantInfo || ({} as TenantInfo),
                            login: tenantLogin || ({} as TenantInfo),
                            gtsLicense:
                              !!(gtsLicense && gtsLicense.value.toLowerCase() === 'true') || false,
                          }}
                        >
                          <Router />
                        </TenantInfoContext.Provider>
                      </ThemeFunctionsContext.Provider>

                      <NotificationPopup />
                    </ApplicationContainer>
                  </BrowserRouter>
                </>
              </ErrorBoundary>
            </MsalProvider>
          </Provider>
        ) : (
          <LoadingScreen />
        )}
      </ThemeProvider>
    </MUIThemeProvider>
  );
};

export default App;
