import {
  CountryFlag,
  DivTableHeadingCell,
  DivTableHeadingCell as TableHeadingCell,
  LinkToMessage,
  MergedCell,
  StatusIndicator,
  TableHeadingWithSort,
} from 'components';
import { FixedSizeList as List } from 'react-window';
import { FC, useState } from 'react';
import { MessageDayIconActive } from 'assets/icons';
import { SortDirection } from 'utils/types';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled, { CSSProperties } from 'styled-components';
import { CountryCode, MessageStatus, SortType } from 'utils/types/generalTypes';
import {
  CustomDateContainer,
  TableContainer,
  Row,
  AutoSizerContainer,
  WhiteTableCells,
  HeadingRow,
} from './styled';
import { useDashboardMessageStatusesByDay } from '../dashboardApi';
import { DashboardMessageStatus } from '../types';
import { formatFromUtc } from 'utils/dateFormating';
import { useAppSelector } from 'store/hooks';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule]);

interface RowProps {
  index: number;
  style: CSSProperties;
  data: Array<DashboardMessageStatus>;
}

const RowRender: FC<RowProps> = ({ data, index, style }) => {
  const { area, lastChange, messageDefinitionDescription, status, messageId } = data[index];
  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);
  const [date, hour] =
    lastChange !== null
      ? formatFromUtc(new Date(`${lastChange}Z`), userTimezone?.timeZone).split(' ')
      : [null, null];
  return (
    <Row style={style} isFailure={status === 'FAILURE'}>
      <WhiteTableCells width="2.5rem" align="center" padding="0">
        <CountryFlag countryCode={area.country.isoAlpha2Code as CountryCode} />
      </WhiteTableCells>
      <WhiteTableCells>{messageDefinitionDescription}</WhiteTableCells>
      <WhiteTableCells width="3.5rem" align="center">
        <StatusIndicator status={status as MessageStatus} />
      </WhiteTableCells>
      <WhiteTableCells width="12.1875rem" padding="0">
        <MergedCell>
          {date !== null && hour !== null ? (
            <>
              <CustomDateContainer>{date}</CustomDateContainer>
              <CustomDateContainer>{hour}</CustomDateContainer>
            </>
          ) : (
            <>
              <WhiteTableCells align="center">-</WhiteTableCells>
            </>
          )}
        </MergedCell>
      </WhiteTableCells>
      <WhiteTableCells width="3.5rem" align="center">
        {messageId && <LinkToMessage link={`/app/history/message/${messageId}`} />}
      </WhiteTableCells>
    </Row>
  );
};

interface MessageStatusTableProps {
  date: Date;
}

const AreaRenderer = (params: CustomCellRendererProps) => {
  return <CountryFlag countryCode={params.data.area.country.isoAlpha2Code as CountryCode} />;
};

const MessageStatusRenderer = (params: CustomCellRendererProps) => {
  return <StatusIndicator status={params.data.area.status as MessageStatus} />;
};

const LastChangeCellRenderer = (params: CustomCellRendererProps, timeZone?: string) => {
  const [date, hour] =
    params.value !== null
      ? formatFromUtc(new Date(`${params.value}Z`), timeZone).split(' ')
      : [null, null];

  return (
    <>
      {date} {hour}
    </>
  );
};

export const MessageStatusTable: FC<MessageStatusTableProps> = ({ date }) => {
  const [activeSort, setActiveSort] = useState<SortType>({
    name: '',
    direction: 'none',
  });

  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);

  const updateSort = (name: string, direction: SortDirection) => {
    setActiveSort({ name, direction });
  };
  const { name: sortName, direction: sortDirection } = activeSort;

  const { data } = useDashboardMessageStatusesByDay(date, activeSort);

  const columnDefs = [
    {
      field: 'area.country.isoAlpha2Code',
      headerName: 'Area',
      checkboxSelection: false,
      // editable: false,
      cellRenderer: AreaRenderer,
      // sortable: false,
      maxWidth: 100,
      rowGroup: true,
      enableRowGroup: true,
      showRowGroup: true,
    },
    {
      field: 'messageDefinitionDescription',
      headerName: 'Message',
      sortable: false,
      flex: 1,
    },
    { field: 'status', cellRenderer: MessageStatusRenderer, sortable: false, maxWidth: 80 },
    {
      field: 'lastChange',
      headerName: 'Last change',
      maxWidth: 180,
      minWidth: 240,
      sortable: true,
      cellRenderer: (params: CustomCellRendererProps) =>
        LastChangeCellRenderer(params, userTimezone?.timeZone),
    },
    {
      headerName: 'Action',
      field: 'messageId',
      sortable: false,
      maxWidth: 80,
      cellRenderer: (params: CustomCellRendererProps) => {
        return params.value && <LinkToMessage link={`/app/history/message/${params.value}`} />;
      },
    },
  ];

  return (
    // <TableContainer>
    //   <HeadingRow>
    //     <TableHeadingCell width="2.5rem" align="center" padding="0">
    //       Area
    //     </TableHeadingCell>
    //     <TableHeadingCell>Message</TableHeadingCell>
    //     <TableHeadingCell width="3.5rem" align="center" padding="0">
    //       Status
    //     </TableHeadingCell>
    //     <TableHeadingSortContainer width="12.1875rem">
    //       <TableHeadingWithSort
    //         name="lastChange"
    //         direction={sortName === 'lastChange' ? sortDirection : 'none'}
    //         setFilter={updateSort}
    //       >
    //         <MessageDayIconActive />
    //         Last change
    //       </TableHeadingWithSort>
    //     </TableHeadingSortContainer>
    //     <TableHeadingCell width="3.5rem" align="center" padding="0">
    //       Action
    //     </TableHeadingCell>
    //   </HeadingRow>
    //   {data?.content && (
    //     <AutoSizerContainer>
    //       <AutoSizer>
    //         {({ height, width }) => (
    //           <List
    //             width={width}
    //             height={height}
    //             itemCount={data.content.length}
    //             itemSize={32}
    //             itemData={data.content}
    //           >
    //             {RowRender}
    //           </List>
    //         )}
    //       </AutoSizer>
    //     </AutoSizerContainer>
    //   )}
    // </TableContainer>
    <div className={'ag-theme-quartz'} style={{ height: '40vh' }}>
      <AgGridReact
        rowData={data?.content}
        groupDisplayType={'groupRows'}
        groupRowRendererParams={{ checkbox: true, rowDrag: true }}
        rowGroupPanelShow={'always'}
        // groupDefaultExpanded={1}
        columnDefs={columnDefs}
        // rowSelection="multiple"
        suppressRowClickSelection={true}
        pagination={false}
      />
    </div>
  );
};
